@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@200;300;400;500;600&display=swap);
@font-face {
  font-family: "Jakarta";
  src: url("/font/PlusJakartaDisplay-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  font-family: "Jakarta" !important;
}

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  transition-delay: 100ms;
  -webkit-transform: scale(0);
          transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  -webkit-transform: scale(1);
          transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  -webkit-transform: scale(0);
          transform: scale(0);
  /* transform: translateY(-150%); */
}
#new-rate {
  color: black;
  font-size: 24px;
}

/* body {
  font-family: "Montserrat", sans-serif;
} */

input {
  max-width: none !important;
}

.swal2-cancel {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100% !important;
  width: 40px !important;
  height: 40px !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMqSURBVHgB7Zc/TBNRHMe/71q0xg51geiAFXTRhRjjACTUYC8w2e4EiIkLE5hIykRZtDExEFdNJIHJmBQGI7UQq4GEwaEhMXEQPHViKgSIpdB7vFdo07vevbsr7UDCJ2l6fX/uPn3v97v3HnDOGYPAIYH4sO+i98Ig69oFQtpA4S/WUUK3CCVpdpXOH5L5xd5YCg6xLdTzKeKnDRhnAoN2+7C7K6qKiaQcm7bfxQZyMjLOGkZRLUyMHODBQm9MsW4q4Hh6PHF+iVqQJyOJnhdTqEboZIq+lMdILaBspD8HYxNm9ZJpRzeJ11qGw6deToyF4ERIXoqMM6U21Aki4R2fAdgR4g2JeooAtgX1UTedhB2hQmoLuOz2oMnjg1Ub/hFDQuzPB/SlrvIfhWGUMA0Bb9ufoq/lYeF6LfO7or7JcwVT94fw+GYPdg+z+Ln9z/xmhG6vz6wslBdpRijvEqc3fxj/cLhUX0t3Rf3Le09KbVq9VyGESAP6Io0QIfQRBGxmM5jdWCr9LpfSy+jbGkN9wY/PNMnj1rXww4LZjcUTme6SFI+X9sY7GpnR728K35Y0uLhQ2lCIgNhKdb1UuLmzVOdIhj9T966TUCVcKv53RVPGg9iJTEGI4DpqIcSnp73xtqbMy6YueO0unEAp/sBMiIJuwaZMeQDvsZEpYpR9TtCPkGLVwSibhlZfm2afNWraVIjt9r6KuvJs0ssUY4bHlF4q1NwBKyRVEggRzIk6e92XhKmtl7JaYtgT0/pNm2bp+DWzrLT2dwyz9DdciHisbGa3sHfwH69+fDDMprXMBqvfRya3g/fKN018VehQjLFnakaoYoPGtqtRVihcYGsChZKQYzf0xRVpz3ZzUd4YdYb9acNdo+F7SIUURl1tyNyCyUnEUCgpP2fzSkZQDyg/gVDTe7vMKtZnlldv9XfyGAugljJ58XHI8lwmL46GiCpNspZ+nI7U/m42nApPCVcDWwdFvpNUXYiy99QAHMKXIwJpIhEUn8ccCVWIAV02RizF0mN+fzc3bTUqVQtp5JKRAIXaRlXJx3aax69kQhXKjs25nVzKicQ5Z5ojCIBC9wx4aCUAAAAASUVORK5CYII=) !important;
  background-position: center !important;
  background-color: white !important;
  background-repeat: no-repeat !important;
}

.swal2-confirm {
  background-color: #48bb78 !important;
}

.checklist {
  /* margin: 200px;
  width: 100%; */

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-left: 50px;
}

h1 {
  font-size: 1.5rem;
}

ul {
  list-style-type: decimal;
}

::marker {
  background-color: #48bb78;
  /* color: white; */
}

ul li {
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 1rem;
}

.form {
  margin: 20px 10px;
}

.form a {
  padding: 10px;
  background-color: #48bb78;
  color: white;
  border-radius: 10px;
  margin-right: 10px;
  font-weight: bold;
}

a {
  font-weight: bold;
}

span {
  padding-left: 5px;
}

@media screen and (max-width: 900px) {
  .checklist {
    margin: 80px;
    width: 80%;
  }

  h1 {
    font-size: 1rem;
  }
}

.makeStyles-spinner-1 {
  margin-top: 0 !important;
}
/* .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
    width: 50%;
} */
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.iTSDrX {
  width: 35%;
  height: auto !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.invoice-box {
  max-width: 100%;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box-2 {
  max-width: 100%;
  margin: auto;
  padding: 30px;
  /* border: 1px solid #eee; */

  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/* RTL */
.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial,
    sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

.error-page {
  display: flex;
  /* background-image: url("../../assets/500.svg"); */

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* align-items: center;
justify-content: center;
background-size: 50%;
background-repeat: no-repeat;
background-position: center; */
  background: rgb(248, 248, 248);
  flex-direction: column;
}
.error-image {
  height: 50%;
  background-image: url(/static/media/500.6420da51.svg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
}

.error-instruction {
  text-align: center;
  height: 50%;
  color: slategray;
}

.error-header {
  font-size: 40px;
}

.file__card {
  margin: 400px;
  width: 400px;
  background-color: #edf2f7;
  border: 3px dashed #cbd5e0;
  padding: 1em;
  min-width: 400px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.file__input {
  position: relative;
  margin-bottom: 1.5em;
  background-color: red;
}

input {
  position: relative;
  max-width: 200px;
  height: 46px;
  z-index: 2;
  cursor: pointer;
}

.btn {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #f55e30;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  outline: none;
}

.icon {
  width: 1.5em;
  height: 1.5em;
  background-color: #fff;
  color: #48bb78;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8em;
  font-size: 0.8em;
}

